<template>
    <div class="home-bg" :style="{backgroundImage: 'url(' + bg + ')'}">
        <div v-if="url"  class="banner_all" :style="{backgroundImage: 'url(' + url + ')'}" ></div>
        <div v-if="pageType == 1" style="margin-top:-360px">
            <div class="main-one">
                <div class="titless">
                    <div class="title-all">
                        <div @click="cutClicki('first')" :class="[activeName == 'first' ?'active':'']" class="haiyun">海运介绍 </div>
                        <div @click="cutClicki('second')" :class="[activeName == 'second' ?'active':'']" class="kongyun">空运介绍</div>
                    </div>
                </div>
                
                <div v-html="contentn"></div>
            </div>
        </div>
        <div style="height:30px"></div>
    </div>
</template>
<script>
import {webexplain,settings,getset} from '@/api/index'
export default {
    data(){
        return{
            pageType:1,
            contentn:'',
            contentns:'',
            form:{
                shr:'',
                phone:'',
                address:'',
            },
            url:'',
            bg:'',
            activeName: 'first'
        }
    },
    created(){
        this.getNowTxt()
        // this.getsettings()
        getset({name:'guidance_banner'}).then(res=>{this.url = this.$store.state.baseUrl + res.data})
        getset({name:'guidance_bg'}).then(res=>{this.bg = this.$store.state.baseUrl + res.data})
    },
    methods:{
        // 获取国内中转仓库地址
        getsettings(){
            settings({type:1}).then(res=>{
                this.form = res.data
            })
        },
        cutClicki(type){
            if(type == this.activeName) return
            this.activeName = type
            this.getNowTxt()
        },
        // 获取协议
        getNowTxt(){
            let type = 0
            if(this.activeName == 'first') type = 1
            else type = 2
            webexplain({type}).then(res=>{
                this.contentn = res.data
            })
        },
        nowButtonClick(type){
            if(type == 1){
                this.pageType = 2
            }else{
                this.$router.push('/home')
            }
            
        },
    }
}
</script>

<style>
.el-tabs__item{
    font-size: 30px;
    font-weight: 700;
}
</style>
<style scoped>
.all-image{
    margin: 84px 0 90px 94px;
    width: 430px;
}
li{
    color: #FF9515;
    list-style-type:disc ;
    line-height: 42px;
    margin-bottom: 60px;
}
li span{
    color: #000000;
}
.main-one{
    width: 80%;
    margin: auto;
    padding: 40px;
    border: 1px solid #ccc;
    background-color: rgba(255, 255, 255, 0.7)  !important;
    transition: 1s;
    box-sizing: border-box;
}
.now-botton-all{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.now-button{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 620px;
    height: 90px;
    background: #5E89FF;
    box-shadow: 0px 9px 18px 0px rgba(94, 137, 255, 0.18);
    border-radius: 45px;
    font-size: 30px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 24px;
    padding: 0 32px;
    margin-right: 100px;
    cursor: pointer;
}
.icon-jinru{
    width: 50px;
    height: 50px;
    background: #FFFFFF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5E89FF;
}

.web-title{
    width: 90%;
    margin: auto;
    display: flex;
    align-items: flex-start;
}
.web-left{
    flex:1
}
.web-right{
    flex:2;

}
.shou-image{
    width: 45px;
    margin-right: 20px;
}
.web-main{
    color: #FFFFFF;
    width: 756px;
    height: 792px;
    margin-top: 140px;
    padding: 70px ;
    background: #23367C;
    box-shadow: 0px 0px 59px 0px rgba(188, 188, 188, 0.33);
}
.web-title-one{
    display: flex;
    align-items: center;
    font-size: 26px;
    color: #FFFFFF;
    margin-bottom: 70px;

}
.web-title-qian-all{
    display: flex;
    align-items: center;
    margin-bottom: 60px;
}
.web-title-qian-all:net-child(3){
    margin-bottom: 50px;
}
.web-title-qian{
    width: 150px;
}
.text-all{
    background: #F8F8F8;
    border-radius: 10px;
    font-size: 26px;
    color: #23367C;
    line-height: 35px;
    padding: 30px ;
}
.now-button-two{
    width: 100%;
    height: 90px;
    background: #FFCC5F;
    box-shadow: 0px 9px 18px 0px rgba(35, 54, 124, 0.18);
    border: none;
    border-radius: 50px;
    font-size: 30px;
    color: #FFFFFF;
    margin-top: 100px;

}
.banner_all{
  width: 100%;
  height: 900px;
}
.zhanwei{
    height: 120px;
}
.home-bg{
    background: url('../../assets/image/login-bg.png') repeat fixed;
    background-size: cover; 
    background-position: center center;
}
.title-all{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    font-size: 36px;
    margin-bottom: 30px;
}
.haiyun{
    cursor: pointer;
    line-height: 60px;
    padding: 10px 20px;
}
.kongyun{
    margin-left: 80px;
    cursor: pointer;
    line-height: 60px;
}
.active{
    background: #f7b832;
    color: #FFFFFF;
    border-radius: 10px;
}
.titless{
    border-bottom:4px dashed #f6f6f6;
    margin-bottom: 60px;
}
</style>